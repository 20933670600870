import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import TicketsPage from "./pages/TicketsPage";
import IndexPage from "./pages/IndexPage";
import HelpPage from "./pages/HelpPage";
import AppPage from "./pages/AppPage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/b/:bookingId/:ticketId" exact component={TicketsPage} />
        <Route path="/b/:bookingId" exact component={TicketsPage} />
        <Route path="/help" exact component={HelpPage} />
        <Route path="/app" exact component={AppPage} />
        <Route path="/" exact component={IndexPage} />
        <Route path="/">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
