import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import useLocalStorage from "../hooks/useLocalStorage";
import { Booking, BookingItem } from "../model";
import Layout from "../layout/Layout";
import { ImBin, ImConnection, ImMobile2, ImQrcode } from "react-icons/im";
import { Heading1 } from "../widgets";

function IndexPage() {
  const [bookings, setBookings] = useLocalStorage<Booking[]>("bookings", []);

  const removeBooking = (booking: Booking) => {
    setBookings((bookings) =>
      bookings.filter((b) => b.bookingId !== booking.bookingId),
    );
  };

  const sortedBookings = useMemo(() => {
    return bookings.sort((a, b) =>
      new Date(a.paidAt) < new Date(b.paidAt) ? 1 : -1,
    );
  }, [bookings]);

  return (
    <Layout>
      <div className="mb-6">
        <Heading1 title="Tickets" />
        <p className="has-text-centered is-size-6">
          Entdecke die Vorteile der Swiss Activities Tickets App
        </p>
      </div>
      <div className="block mb-6">
        <div className="benefits">
          <div>
            <ImConnection size={40} />
            <div>
              <h4 className="is-black">Offline verfügbar</h4>
              <p>Tickets sind auch ohne Internet-Verbindung immer abrufbar</p>
            </div>
          </div>
          <div>
            <ImMobile2 size={40} />
            <div>
              <h4>Installierbar</h4>
              <p>
                Die Webseite kann als App direkt auf dem Smartphone installiert
                werden
              </p>
            </div>
          </div>
        </div>
      </div>

      {sortedBookings.length === 0 && (
        <div className="notification is-warning is-light">
          <p className="has-text-weight-bold">
            Du hast momentan keine Tickets hinzugefügt.
          </p>
          <p>
            Öffne einfach den Link in deiner Bestätigungsmail um sie automatisch
            hier hinzuzufügen.
          </p>
        </div>
      )}

      {sortedBookings.map((booking) => (
        <div key={booking.bookingId} className="block">
          {booking.items.map((bookingItem) => (
            <BookingItemCard
              key={bookingItem.bookingItemId}
              bookingItem={bookingItem}
              booking={booking}
              onRemove={() => removeBooking(booking)}
            />
          ))}
        </div>
      ))}
    </Layout>
  );
}

function BookingItemCard({
  bookingItem,
  booking,
  onRemove,
}: {
  bookingItem: BookingItem;
  booking: Booking;
  onRemove: () => void;
}) {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onToggle = () => setIsOpen(!isOpen);

  return (
    <div key={bookingItem.bookingItemId} className="card is-booking">
      <div className="card-content">
        <div className="block is-clickable" onClick={onToggle}>
          <h2 className="title is-5">{bookingItem.activity.label}</h2>
          <div className="subtitle is-6">{bookingItem.supplier.name}</div>
        </div>

        {bookingItem.tickets.map((ticket) => (
          <div
            key={ticket.ticketId}
            onClick={() => history.push(`/b/${booking.bookingId}`)}
            className="is-flex is-align-items-center is-justify-content-space-between is-clickable block"
          >
            <div className="pr-2">
              <div>{ticket.customer.name}</div>
              <div className="is-size-7 is-uppercase">
                {ticket.ticketCategory.description}
              </div>
            </div>
            <div>
              <ImQrcode size={32} />
            </div>
          </div>
        ))}
      </div>
      <div className={`card-footer ${isOpen ? "is-opened" : ""}`}>
        <div className="card-footer-item is-clickable" onClick={onRemove}>
          <ImBin />
        </div>
      </div>
    </div>
  );
}

export default IndexPage;
