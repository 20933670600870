import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import QRCode from "react-qr-code";

import useLocalStorage from "../hooks/useLocalStorage";
import useWindowDimensions from "../hooks/useWindowDimensions";
import useElementDimensions from "../hooks/useElementDimensions";
import useApi from "../hooks/useApi";

import { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { ImCalendar, ImClock, ImMail3 } from "react-icons/im";

import { Booking, BookingItem, Ticket } from "../model";
import Layout from "../layout/Layout";
import Dialog from "../layout/Dialog";
import { DateTime, Logo } from "../widgets";

type RouteParams = { bookingId: string; ticketId?: string };

type TicketWithBookingItem = Ticket & {
  booking_item: BookingItem;
};

function TicketsPage() {
  const api = useApi();
  const history = useHistory();
  const { bookingId, ticketId } = useParams<RouteParams>();
  const { width } = useWindowDimensions();

  const [bookings, setBookings] = useLocalStorage<Booking[]>("bookings", []);
  const [bookingsData, setBookingsData] = useLocalStorage<Booking[]>(
    "bookingsData",
    [],
  );
  const [tickets, setTickets] = useState<TicketWithBookingItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const numSlides = useMemo(() => {
    const minSlideWidth = 400;
    const numSlides = Math.floor(width / minSlideWidth);
    return numSlides < 1 ? 1 : numSlides;
  }, [width]);

  useEffect(() => {
    setIsLoading(true);
    api.get<Booking | null>(`/bookings/${bookingId}`).then((booking) => {
      if (booking) {
        setBookingsData((bookingsData) =>
          bookingsData
            .filter((b) => b.bookingId !== booking.bookingId)
            .concat(booking),
        );
      }
    });
    api
      .get<Booking | null>(`/bookings/${bookingId}/tickets_data`)
      .then((booking) => {
        if (booking) {
          setBookings((bookings) =>
            bookings
              .filter((b) => b.bookingId !== booking.bookingId)
              .concat(booking),
          );
        } else {
          setIsNotFound(true);
        }
      })
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, [bookingId]);

  const booking = bookings.find((b) => b.bookingId === bookingId);
  const locale = (
    bookingsData.find((b) => b.bookingId === bookingId) as unknown as {
      locale: string;
    }
  )?.locale;

  useEffect(() => {
    if (booking) {
      setTickets(
        booking.items.reduce((tickets, item) => {
          const ticketsWithItems = item.tickets.map((ticket) => {
            return {
              ...ticket,
              booking_item: item,
            } as TicketWithBookingItem;
          });

          return tickets.concat(...ticketsWithItems);
        }, [] as TicketWithBookingItem[]),
      );
    }
  }, [booking]);

  const selectedTicket = useMemo<Ticket | null>(() => {
    return tickets.find((t) => t.ticketId === ticketId) || null;
  }, [tickets, ticketId]);

  if (tickets.length === 0) {
    if (isLoading) {
      return <div className="pageloader is-active" />;
    }

    if (isNotFound) {
      return (
        <Layout centered>
          <div className="has-text-centered">
            <h1 className="title is-3">404</h1>
            <div className="content">
              <p>
                Die Buchung <b>{bookingId}</b> wurde leider in unserem System
                nicht gefunden.
              </p>
              <p>
                Bitte überprüfe ob die Buchungsnummer korrekt ist. Falls du
                weitere Hilfe brauchst, kontaktiere uns doch bitte.
              </p>
            </div>
            <div>
              <a
                className="button is-primary"
                href="mailto://support@swissactivities.com"
              >
                <ImMail3 />{" "}
                <span className="ml-2">support@swissactivities.com</span>
              </a>
            </div>
          </div>
        </Layout>
      );
    }
  }

  return (
    <>
      {selectedTicket && (
        <div className="fullscreen-qr-code" onClick={() => history.goBack()}>
          <div className="qrcode">
            <QRCode
              value={getQrCodeValue(selectedTicket)}
              size={width - 40 > 500 ? 500 : width - 40}
            />
          </div>
        </div>
      )}

      <Dialog title="Tickets">
        <Swiper
          enabled={!selectedTicket}
          modules={[Navigation, Pagination]}
          spaceBetween={0}
          pagination={{ type: "fraction" }}
          navigation={true}
          slidesPerView={numSlides}
        >
          {tickets.map((ticket) => (
            <SwiperSlide key={ticket.ticketId}>
              <TicketInfo ticket={ticket} locale={locale} />
            </SwiperSlide>
          ))}
        </Swiper>
      </Dialog>
    </>
  );
}

function TicketInfo({
  ticket,
  locale = "de_CH",
}: {
  ticket: TicketWithBookingItem;
  locale?: string;
}) {
  const { bookingId } = useParams<RouteParams>();
  const history = useHistory();

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useElementDimensions(ref);

  return (
    <div ref={ref} className="ticket mt-5">
      <div className="ticket-header">
        <Logo />
        <h1>Ticket</h1>
      </div>

      <div className="ticket-content">
        <div className="details">
          <h2>{ticket.booking_item.activity.label}</h2>

          <div className="price-and-date">
            <div className="date">
              <div>
                <ImCalendar size={12} className="mr-2" />
                <DateTime
                  date={ticket.booking_item.startsAt}
                  format={{ month: "numeric", day: "numeric", year: "numeric" }}
                />
              </div>
              <div>
                {!ticket.booking_item.isAllDay && (
                  <>
                    <ImClock size={12} className="mr-2" />
                    <DateTime
                      date={ticket.booking_item.startsAt}
                      format={{ hour: "numeric", minute: "2-digit" }}
                    />{" "}
                    Uhr
                  </>
                )}
              </div>
            </div>
            <div className="price">
              <div className="amount">{ticket.amountFormatted}</div>
              <div className="vat">inkl. 8.1% MwSt.</div>
            </div>
          </div>

          <div className="customer-info">
            <div className="customer-name">{ticket.customer.name}</div>
            <div className="ticket-category">
              {ticket.ticketCategory.description}
            </div>
          </div>
        </div>
        <div className="separator" />
        {["pdf_file", "html_to_pdf_file"].includes(
          ticket.externalTicketType,
        ) ? (
          <div className="is-flex is-justify-content-center">
            <a
              target="_blank"
              href={ticket?.externalTicketId || undefined}
              className="button is-primary"
            >
              {locale === "de_CH"
                ? "Ticket öffnen"
                : locale === "en_CH"
                  ? "Open ticket"
                  : locale === "fr_CH"
                    ? "Ouvrir le ticket"
                    : locale === "it_CH"
                      ? "Apri ticket"
                      : "Ticket öffnen"}
            </a>
          </div>
        ) : (
          <div className="stub">
            <div
              className="qrcode"
              onClick={() => history.push(`/b/${bookingId}/${ticket.ticketId}`)}
            >
              <QRCode value={getQrCodeValue(ticket)} size={width / 2.5} />
            </div>
            <div className="ticket_id">{getQrCodeValue(ticket)}</div>
          </div>
        )}
      </div>
    </div>
  );
}

function getQrCodeValue(ticket: Ticket) {
  return ticket.externalTicketId || ticket.ticketId;
}

export default TicketsPage;
