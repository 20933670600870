import React from "react";
import Layout from "../layout/Layout";
import { ImMobile } from "react-icons/im";
import { useAddToHomescreenPrompt } from "../hooks/useAddToHomescreen";
import { Heading1 } from "../widgets";

function AppPage() {
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const onInstall = () => {
    promptToInstall();
  };

  return (
    <Layout centered>
      <div className="has-text-centered">
        <Heading1 title="Als App installieren" />
        {prompt ? (
          <>
            <div className="content">
              <p>
                Mit nur einem Klick kannst du auf deinem Smartphone ein App Icon
                für deine Tickets installieren.
              </p>
              <p>Du musst dazu nicht extra in den App Store gehen.</p>
            </div>

            <button
              className="button is-primary is-outlined is-large"
              onClick={onInstall}
            >
              <ImMobile /> <span className="ml-2">Installieren</span>
            </button>
          </>
        ) : (
          <div className="content">
            <p>
              Tut uns Leid, aber scheinbar unterstützt dein Gerät "Add 2
              Homescreen" nicht.
            </p>
          </div>
        )}
      </div>
    </Layout>
  );
}

export default AppPage;
