import React, { ReactNode } from "react";
import { ImArrowLeft2 } from "react-icons/im";
import { Link } from "react-router-dom";

function Dialog({ title, children }: { title?: string; children: ReactNode }) {
  return (
    <main>
      <nav
        className="navbar is-fixed-top is-white"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" role="button" className="navbar-item">
            <ImArrowLeft2 />
          </Link>
          <div className="navbar-item sa">{title}</div>
        </div>
      </nav>
      {children}
    </main>
  );
}

export default Dialog;
