import React, { ReactNode } from "react";
import Navbar from "./Navbar";

function Layout({
  children,
  centered = false,
}: {
  children: ReactNode;
  centered?: boolean;
}) {
  return (
    <>
      <Navbar />
      <main className={centered ? "centered" : ""}>
        <div className="container p-3 pb-6">{children}</div>
      </main>
    </>
  );
}

export default Layout;
