import { RefObject, useEffect, useState } from "react";

type Dimensions = {
  width: number;
  height: number;
};

function getDimensions(ref: RefObject<HTMLElement>): Dimensions {
  return {
    width: ref.current?.offsetWidth || 0,
    height: ref.current?.offsetHeight || 0,
  };
}

export default function useElementDimensions(
  ref: RefObject<HTMLElement>,
): Dimensions {
  const [dimensions, setDimensions] = useState<Dimensions>(getDimensions(ref));

  useEffect(() => {
    setDimensions(getDimensions(ref));

    function handleResize() {
      setDimensions(getDimensions(ref));
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return dimensions;
}
