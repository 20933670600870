const host = process.env.REACT_APP_BOOKINGAPI_HOST;

export default function useApi() {
  function get<T>(path: string): Promise<T> {
    return fetch(`${host}${path}`).then((rsp) => {
      if (rsp.status === 404) {
        return null;
      }

      if (!rsp.ok) {
        throw new Error("Failed to retrieve booking");
      }

      return rsp.json();
    });
  }

  return { get };
}
