import React from "react";
import Layout from "../layout/Layout";
import { ImMail3 } from "react-icons/im";
import { Heading1 } from "../widgets";

function HelpPage() {
  return (
    <Layout centered>
      <div className="has-text-centered">
        <Heading1 title="Hilfe" />
        <div>
          <a
            className="button is-primary"
            href="mailto://support@swissactivities.com"
          >
            <ImMail3 />{" "}
            <span className="ml-2">support@swissactivities.com</span>
          </a>
        </div>
      </div>
    </Layout>
  );
}

export default HelpPage;
