import { useState } from "react";
import { Link } from "react-router-dom";
import { LogoMobile } from "../widgets";

function Navbar() {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <nav
      className="navbar is-fixed-top is-white has-shadow"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item sa" to="/">
          <LogoMobile />
        </Link>
        <div
          tabIndex={0}
          role="button"
          className={`navbar-burger ${isOpen ? "is-active" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
          aria-label="menu"
          aria-expanded="false"
          data-target="mainNav"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </div>
      </div>

      <div id="mainNav" className={`navbar-menu ${isOpen ? "is-active" : ""}`}>
        <div className="navbar-end">
          <Link className="navbar-item" to="/help">
            Hilfe
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
